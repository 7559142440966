import React from 'react';
import BlogArticle from '../../../components/common/BlogArticle';
import SEO from '../../../components/SEO';

import { useScrollToTop } from '../../../hooks/useScrollToTop';
import blogImage from '../../../assets/img/blog/blog-3.jpeg';

import './BlogArticlePage.scss';

interface BlogData {
    id: number;
    title?: string;
    text: string;
    type: string;
    subtype: string;
}

interface BlogItems extends Array<BlogData> {}

const BlogArticlePage = () => {
    const contentData = [
        {
            id: 0,
            text: 'Hire the Right People',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 1,
            text: 'Establish Clear Communication Channels',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 2,
            text: 'Use the Right Tools',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 3,
            text: 'Keep the Team Motivated',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 4,
            text: 'Set Clear Expectations',
            type: 'margin',
            subtype: 'blog',
        },
    ];

    const blogData: BlogItems = [
        {
            id: 0,
            text:
                'Are you considering building a remote development team? If so, you’re not alone. More and more companies are turning to remote teams to take advantage of the many benefits that come with distributed software engineering. But managing a remote team takes careful planning, effective communication, and a solid understanding of the best practices for managing distributed software engineering. As experts in the field, we’ve seen firsthand what it takes to build and manage a successful remote team. In this post, we’ll share some of our best practices for managing distributed software engineering, so you can create a remote development team that thrives.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 1,
            title: 'Hire the Right People',
            text:
                'The first step to building a successful remote development team is to hire the right people. Look for candidates who are self-motivated, communicative, and can work independently. You want team members willing to take ownership of their work and collaborate with others effectively. It’s also vital to hire team members with experience working remotely, as they’ll be familiar with the unique challenges of working on a distributed team.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 2,
            title: 'Establish Clear Communication Channels',
            text:
                'Effective communication is key to managing a remote team. Establish clear communication channels to ensure that everyone is on the same page, such as instant messaging, video conferencing, and email, and that everyone on the team knows how to use them. Encourage team members to use video conferencing for more personal interactions and to build stronger relationships. Establishing a regular meeting schedule is essential, so team members can stay up-to-date on project progress and address any issues. This includes daily stand-ups, regular team meetings, and one-on-one meetings.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            title: 'Use the Right Tools',
            text:
                'There are many tools available to help manage a remote team. Use Trello, Asana, or Jira to manage projects, assign tasks, and track progress. Also, use cloud-based file-sharing platforms like Dropbox or Google Drive to share files and documents. Ensure everyone on the team is comfortable with your tools, and provide training if necessary.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            title: 'Keep the Team Motivated',
            text:
                'Encourage team members to share their ideas, collaborate on projects, and ensure everyone feels included and valued. Make sure that everyone on the team feels valued and appreciated. Recognize team members for their contributions, celebrate successes, and provide feedback on areas for improvement. Establish team-building activities, such as online games or trivia nights, to help build team morale. Create a virtual water cooler where team members can chat and connect more personally.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 5,
            title: 'Set Clear Expectations',
            text:
                'Setting clear expectations is a must for managing a remote team. Establish project timelines, set goals and objectives, and communicate them clearly to team members. Ensure everyone knows what is expected of them and when, and provide regular feedback on their progress.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 6,
            text:
                'Don’t just take our word for it. We spoke with successful remote teams and got their insights on building and managing a successful distributed software engineering team. Here’s what they had to say:',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 7,
            text:
                'One of the keys to success is ensuring everyone on the team feels connected and valued. It can be easy to feel isolated when working remotely. Hence, create opportunities for team members to connect and collaborate.” — Alex, Senior Software Engineer.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 8,
            text:
                '“Communication is key when working on a remote team. Regular check-ins and meetings are important to stay up-to-date.” — Olena, Project Manager.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 9,
            text:
                'We hope that these best practices will be helpful for those who are considering building and managing remote development teams. With the right approach, remote teams can be as effective as in-house teams and provide significant benefits for employers and employees.',
            type: 'mb',
            subtype: 'blog',
        },
    ];

    useScrollToTop();

    return (
        <div>
            <SEO
                pageTitle="Blackbird Lab - Blog"
                pageDescription="Building a Remote Development Team: Best Practices for Managing Distributed Software Engineering"
                pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/blog/articles/building-a-remote-development-team.png"
            />
            <BlogArticle
                title="Building a Remote Development Team: Best Practices for Managing Distributed Software Engineering"
                image={blogImage}
                author="Yurii Drozd"
                date="May 15, 2023"
                blogData={blogData}
                contentData={contentData}
            />
        </div>
    );
};

export default BlogArticlePage;
